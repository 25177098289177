html, body, #root {
  height: 100%;
  min-height: 100%;
  background-color: black;
  font-family: 'Kalam', cursive;
}

* {
  font-family: 'Indie Flower', cursive;
}

#viewport {
  position: relative;
  width: 100%;
  height: 100%;
}

.default-modal {
  width: 100%;
  height: 100%;
  max-width: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.default-overlay {
  padding: 0;
}

.card {
  background-color: white;
  padding: 20px;
}