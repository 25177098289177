#play-stage {
  display: flex;
  flex-direction: row;
  height: 100%;

  #suspects {
    width: 25%;
    flex-direction: column;
    display: flex;
    padding: 8% 4px 4px 4%;
    background: url("../../assets/sidebar_back.png") no-repeat center center;
    background-size: 100% 100%;
    margin-left: -6px;
    z-index: 100;

    .suspects-row {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
    }
  }

  .suspect-badge {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 3px;
    padding-bottom: 10px;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
    max-width: 50%;

    > img {
      max-width: 100%;
      max-height: 100%;
    }

    .suspect-info {
      background-color: rgba(white, 0.9);
      position: absolute;
      bottom: -5px;
      left: 5%;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: solid 4px #B7985F;
      margin-left: -4px;
      padding-top: 2px;

      > span {
        margin-left: 5px;
      }
      > b {
        margin-right: 5px;
      }
    }
  }

  #murder-room {
    width: 75%; // 4:3 aspect ratio
    background: no-repeat center center;
    background-size: 100% 100%;
    position: relative;

    .item-badge {
      position: absolute;
      cursor: pointer;
      animation: ease all 0.5s;
      transform-origin: left top;

      &.is-found {
      }
    }
  }
}

.item-found-slide-found-item-card {
  position: relative;

  > img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .police-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .item-found-slide-found-item-card-probability {
    position: absolute;
    top: 68%;
    left: 55%;
    font-size: 50px;
    color: black;
    transform: rotate(-10deg);
  }
}

.item-found-slide-suspects-overview {
  width: 100%;
  height: 100%;

  background: url("../../assets/suspects_overview_background.png") center center;
  background-size: contain;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  table {
    padding: 20px;
    max-width: 100%;
    width: 100%;
    color: white;

    td {
      width: calc(90% / 6);
      text-align: center;
      border-bottom: solid 2px rgba(white, 0.1);
    }

    td:first-child {
      width: 8%;
    }

    img {
      max-width: 90%;
    }

    tbody {
      td {
        padding: 7px 0;
      }
    }
  }

  .suspect-overview-badge {
    border: solid 5px black;
  }

  .suspect-percent {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.suspect-selected-card {
  background: url("../../assets/suspects_overview_background.png") center center;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .explanation {
    background: url("../../assets/SuspeitosOvl.png") no-repeat;
    background-size: contain;
    flex-grow: 1;

    padding: 30% 5% 4% 5%;
  }
}

.fill {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formula-row {
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  p {
    position: relative;
    margin: 0 5px;
    font-size: 28px;
    font-weight: bold;

    label {
      position: absolute;
      left: 50%;
      bottom: 95%;
      opacity: 0.8;
      font-size: 12px;
      transform: rotate(-55deg);
      transform-origin: left center;
    }
  }

}