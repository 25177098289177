#start {
  align-items: center;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  width: 100%;

  .logo-text-msg {
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
    animation: blink-animation 1s steps(5, start) infinite;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}